// Link to Generate the following list from .xlsx file https://github.com/Instamojo/frontend-server/pull/407
export const PERSONA = {
  PROFILE_VIEW: 'persona.profile-view',
  DASHBOARD_VIEW: 'persona.dashboard-view',
  SIDEBAR_VIEW: 'persona.sidebar-view',
  USER_DETAIL_VIEW: 'persona.user-detail-view',
};

export const SETTINGS = {
  PROFILE_VIEW: 'settings.profile-view',
  PROFILE_UPDATE: 'settings.profile-update',
  PROFILE_PUBLIC_CONTACT: 'settings.profile-public-contact',
  PROFILE_PUBLIC_CONTACT_DELETE: 'settings.profile-public-contact-delete',
  PROFILE_PHOTO_UPDATE: 'settings.profile-photo-update',
  PROFILE_ADDRESS_VIEW: 'settings.profile-address-view',
  USERNAME_UPDATE: 'settings.username-update',
  SOCIAL_LINKS_VIEW: 'settings.social-links-view',
  SOCIAL_LINKS_UPDATE: 'settings.social-links-update',
  CART_UPDATE: 'settings.cart-update',
  STORE_UPDATE: 'settings.store-update',
  INSTAPAY_VIEW: 'settings.instapay-view',
  PAYOUT_VIEW: 'settings.payout-view',
  PAYOUT_UPDATE: 'settings.payout-update',
  PAYOUT_ADDRESS_UPDATE: 'settings.payout-address-update',
  EXPRESS_PAYOUT_VIEW: 'settings.express-payout-view',
  EXPRESS_PAYOUT_UPDATE: 'settings.express-payout-update',
  BANK_TRANSFER_VIEW: 'settings.bank-transfer-view',
  BANK_TRANSFER_UPDATE: 'settings.bank-transfer-update',
  PAYMENT_COMPLETION_REMINDER_VIEW: 'settings.payment-completion-reminder-view',
  PAYMENT_COMPLETION_REMINDER_UPDATE: 'settings.payment-completion-reminder-update',
  NDP_ELIGIBILITY_VIEW: 'settings.ndp-eligibility-view',
  PAYOUT_PLAN_VIEW: 'settings.payout-plan-view',
  PAYOUT_PLAN_UPDATE: 'settings.payout-plan-update',
  FASTER_PAYOUT_FEE_VIEW: 'settings.faster-payout-fee-view',
  FASTER_PAYOUT_FEE_UPDATE: 'settings.faster-payout-fee-update',
  CONV_FEE_VIEW: 'settings.conv-fee-view',
  CONV_FEE_UPDATE: 'settings.conv-fee-update',
  CONV_FEE_ORDER_VIEW: 'settings.conv-fee-order-view',
  DOCUMENT_EXPORT_VIEW: 'settings.document-export-view',
  DOCUMENT_EXPORT_UPDATE: 'settings.document-export-update',
  EXPORT_TIME_VIEW: 'settings.export-time-view',
  EXPORT_TIME_UPDATE: 'settings.export-time-update',
  PHONE_VERIFICATION_STATUS_VIEW: 'settings.phone-verification-status-view',
  PHONE_VERIFICATION_OTP_SEND: 'settings.phone-verification-otp-send',
  PHONE_VERIFICATION_OTP_VERIFY: 'settings.phone-verification-otp-verify',
  EMAIL_VERIFICATION_REQUEST: 'settings.email-verification-request',
};

export const PAYMENTS = {
  ALL_VIEW: 'payments.all-view',
  DETAIL_VIEW: 'payments.detail-view',
  LINK_VIEW: 'payments.link-view',
  LINK_DETAIL_VIEW: 'payments.link-detail-view',
  PRODUCT_VIEW: 'payments.product-view',
  PRODUCT_DETAIL_VIEW: 'payments.product-detail-view',
  VAN_VIEW: 'payments.van-view',
  RAP_VIEW: 'payments.rap-view',
  RAP_DETAIL_VIEW: 'payments.rap-detail-view',
  INSTAPAY_VIEW: 'payments.instapay-view',
  EXPORT_SEND_MAIL: 'payments.export-send-mail',
  EXPORT: 'payments.export',
  EXPORT_NONCAPTURED: 'payments.export-noncaptured',
  ALL_EXPORT: 'payments.all-export',
  LINK_EXPORT: 'payments.link-export',
  LINK_DETAIL_EXPORT: 'payments.link-detail-export',
  PRODUCT_EXPORT: 'payments.product-export',
  INSTAPAY_EXPORT: 'payments.instapay-export',
  RAP_DETAIL_EXPORT: 'payments.rap-detail-export',
  RAP_EXPORT: 'payments.rap-export',
  LINK_EXPORT_EMAIL: 'payments.link-export-email',
  LINK_DETAIL_EXPORT_EMAIL: 'payments.link-detail-export-email',
  PRODUCT_EXPORT_EMAIL: 'payments.product-export-email',
  PRODUCT_DETAIL_EXPORT_EMAIL: 'payments.product-detail-export-email',
  INSTAPAY_EXPORT_EMAIL: 'payments.instapay-export-email',
  RAP_EXPORT_EMAIL: 'payments.rap-export-email',
  RAP_DETAIL_EXPORT_EMAIL: 'payments.rap-detail-export-email',
  PAYMENTS_AGGREGATES_VIEW: 'payments.aggregates-view',
};

export const PAYOUTS = {
  DETAIL_BENEFICIARIES_EXPORT: 'payouts.detail-beneficiaries-export',
  ALL_VIEW: 'payouts.all-view',
  DETAIL_VIEW: 'payouts.detail-view',
  DETAIL_TRANSACTIONS_VIEW: 'payouts.detail-transactions-view',
  DETAIL_TRANSACTIONS_EXPORT: 'payouts.detail-transactions-export',
  ALL_IP_VIEW: 'payouts.all-ip-view',
  REQUEST_IP: 'payouts.request-ip',
};

export const INVOICES = {
  ALL_VIEW: 'invoices.all-view',
  ALL_EXPORT: 'invoices.all-export',
};

export const LINKS = {
  ALL_VIEW: 'links.all-view',
  CREATE: 'links.create',
  DETAIL_VIEW: 'links.detail-view',
  UPDATE: 'links.update',
  DELETE: 'links.delete',
  EXTENSION_CREATE: 'links.extension-create',
};

export const SMARTLINKS = {
  ALL_VIEW: 'smartlinks.all-view',
  CREATE: 'smartlinks.create',
  DETAIL_VIEW: 'smartlinks.detail-view',
  UPDATE: 'smartlinks.update',
  DELETE: 'smartlinks.delete',
  BULK_UPDATE: 'smartlinks.bulk-update',
  BULK_DELETE: 'smartlinks.bulk-delete',
  NOTIFY_MOBILE_VIEW: 'smartlinks.notify-mobile-view',
  NOTIFY_MOBILE_UPDATE: 'smartlinks.notify-mobile-update',
  NOTIFY_EMAIL_VIEW: 'smartlinks.notify-email-view',
  NOTIFY_EMAIL_UPDATE: 'smartlinks.notify-email-update',
  SHARE: 'smartlinks.share',
  BULK_SHARE: 'smartlinks.bulk-share',
  PRICING_FIXED_CREATE: 'smartlinks.pricing-fixed-create',
  PRICING_FIXED_UPDATE: 'smartlinks.pricing-fixed-update',
  PRICING_AMOUNT_BREAKUP_CREATE: 'smartlinks.pricing-amount-breakup-create',
  PRICING_AMOUNT_BREAKUP_UPDATE: 'smartlinks.pricing-amount-breakup-update',
  PRICING_DROPDOWN_CREATE: 'smartlinks.pricing-dropdown-create',
  PRICING_DROPDOWN_UPDATE: 'smartlinks.pricing-dropdown-update',
  SMARTLINKS_DISCOUNT_DETAILS: 'smartlinks.discount-detail-view',
  SMARTLINKS_DISCOUNT_CREATE: 'smartlinks.discount-create',
  SMARTLINKS_DISCOUNT_UPDATE: 'smartlinks.discount-update',
  SMARTLINKS_DOMAIN: 'smartlinks.domain',
};

export const REFUNDS = {
  ALL_VIEW: 'refunds.all-view',
  ALL_EXPORT: 'refunds.all-export',
  DETAIL_VIEW: 'refunds.detail-view',
};

export const VAN = {
  ALL_VIEW: 'van.all-view',
  CREATE: 'van.create',
  UPDATE: 'van.update',
  BULK_UPLOAD: 'van.bulk-upload',
  ALL_EXPORT: 'van.all-export',
};

export const CASES = {
  CREATE_BUYER: 'cases.create-buyer',
  EXPORT: 'cases.export',
  CREATE_SELLER: 'cases.create-seller',
  ALL_VIEW: 'cases.all-view',
  WITHDRAW: 'cases.withdraw',
  CONCEDE: 'cases.concede',
  DENY: 'cases.deny',
  ESCALATE: 'cases.escalate',
  DETAIL_VIEW: 'cases.detail-view',
  COMMENT_ADD: 'cases.comment-add',
};

export const COMPLIANCE = {
  TAX_CODE_VIEW: 'compliance.tax-code-view',
  TAX_CODE_UPDATE: 'compliance.tax-code-update',
  GST_REGISTRATION_VIEW: 'compliance.gst-registration-view',
  GST_REGISTRATION_CREATE: 'compliance.gst-registration-create',
};

export const ADVERTS = {
  NAYAMAAL_VIEW: 'adverts.nayamaal-view',
  NAYAMAAL_DETAIL_VIEW: 'adverts.nayamaal-detail-view',
  NAYAMAAL_CLICK: 'adverts.nayamaal-click',
  NAYAMAAL_LANDING_PAGE: 'adverts.nayamaal-landing-page',
};

export const ANALYTICS = {
  VIEW: 'analytics.view',
  VIEW_COUNT: 'analytics.view-count',
  DATA_EXPORT: 'analytics.data-export',
};

export const DOCUMENTS = {
  DOC_LINK_JWT: 'documents.doc-link-jwt',
};

export const AGREEMENTS = {
  DETAIL_VIEW: 'agreements.detail-view',
  DETAIL_MARK_CONSENT: 'agreements.detail-mark-consent',
};

export const FULFILLMENT = {
  DETAIL_VIEW: 'fulfillment.detail-view',
  SUBMIT: 'fulfillment.submit',
};

export const APPSTORE = {
  ALL_VIEW: 'appstore.all-view',
};

export const RESELLER_PROGRAM = {
  AFFILIATE_CREATE_VIEW: 'affiliate.reseller-create',
  AFFILIATE_PRODUCT_LIST_VIEW: 'affiliate.product-list-view',
  AFFILIATE_PRODUCT_VIEW: 'affiliate.product-view',
  AFFILIATE_LINK_SHARE: 'affiliate.link-share',
  AFFILIATE_LINK_BULK_SHARE: 'affiliate.link-bulk-share',
  AFFILIATE_SALES_VIEW: 'affiliate.sales-view',
};

export const MOJOPLUS = {
  USER_STATS_VIEW: 'mojoplus.user-stats-view',
  USER_BADGES_VIEW: 'mojoplus.user-badges-view',
  BADGES_LIST_VIEW: 'mojoplus.badges-list-view',
  POINTS_RATES_VIEW: 'mojoplus.points-rates-view',
  REDEMPTION_CATEGORIES_LIST_VIEW: 'mojoplus.redemption-categories-list-view',
  POST_PURCHASE_VIEW: 'mojoplus.points-purchase-view',
};

export const SMART_PAGES = {
  ALL_VIEW: 'smartpages.all-view',
  CREATE_VIEW: 'smartpages.create',
  DETAIL_VIEW: 'smartpages.detail',
  UPDATE_VIEW: 'smartpages.update',
  ANALYTICS_VIEW: 'smartpages.analytics',
  PREVIEW_VIEW: 'smartpages.preview',
  THEME: 'smartpages.theme',
  UPLOAD: 'smartpages.upload',
  SMARTPAGES_SUBSCRIPTIONS_FETCH_PLAN_DETAIL_ALLOW: 'smartpages-subscriptions.fetch-plan-detail',
};

export const CUSTOMER_INVOICE = {
  SETTING_VIEW: 'customer-invoice.setting-view',
  SETTING_UPDATE: 'customer-invoice.setting-update',
  EXPORT: 'customer-invoice.export',
};

export const SMART_LINKS = {
  SMARTLINKS_SUBSCRIPTIONS_FETCH_PLAN_DETAIL_ALLOW: 'smartlink-subscriptions.fetch-plan-detail',
};
