import React from 'react';
import root from 'window-or-global';

const staticUrl = `${root.STATIC_URL}assets/images/payment/`;
const imageBhim = `${staticUrl}bhim.png`;
const imageUPI = `${staticUrl}upi-logo.png`;
const imagePhonepe = `${staticUrl}phonepe.png`;
const imageGPay = `${staticUrl}gpay.png`;
const imagePaytm = `${staticUrl}paytm.png`;
const imageAirtel = `${staticUrl}airtel-payments-bank-limited.svg`;
const imageAmazon = `${staticUrl}amazon-pay.png`;
const imageCiti = `${staticUrl}citi.svg`;
const imageHSBC = `${staticUrl}hsbc.png`;
const imageKotak = `${staticUrl}kotak.png`;
const imageIcici = `${staticUrl}icici.svg`;

export const findUpiInfo = [
  {
    value: 'bhim',
    imageUrl: imageBhim,
    content: (
      <ol>
        <li>Open BHIM app.</li>
        <li>
          Tap on <b>&quot;Profile&quot; tab below my information.</b>
        </li>
        <li>
          Your <b>UPI ID</b> is below QR Code.
        </li>
      </ol>
    ),
  },
  {
    value: 'tez', // TODO: Change value in the backend to match gpay instead of tez.
    imageUrl: imageGPay,
    content: (
      <ol>
        <li>Open Google Pay app.</li>
        <li>Tap on your name on the top left.</li>
        <li>Your UPI ID is below your email id.</li>
      </ol>
    ),
  },
  {
    value: 'paytm',
    imageUrl: imagePaytm,
    content: (
      <ol>
        <li>Open Paytm app.</li>
        <li>
          Tap on <b>&quot;BHIM UPI&quot;</b> in top nav bar.
        </li>
        <li>Your UPI ID is below QR Code.</li>
      </ol>
    ),
  },
  {
    value: 'phonepe',
    imageUrl: imagePhonepe,
    content: (
      <ol>
        <li>Open PhonePe app.</li>
        <li>
          Tap on <b>&quot;My Account&quot;</b> in nav bar.
        </li>
        <li>
          Then tap on <b>&quot;BHIM UPI ID&quot;</b>.
        </li>
      </ol>
    ),
  },
];

export const UPIList = [
  {
    displayName: '',
    imageUrl: imageBhim,
    value: 'bhim',
    psp: ['@upi'],
  },
  {
    displayName: '',
    imageUrl: imagePhonepe,
    value: 'phonepe',
    psp: ['@ybl', '@ibl', '@axl'],
  },
  {
    displayName: '',
    imageUrl: imageGPay,
    value: 'tez', // TODO: Change value in the backend to match gpay instead of tez.
    psp: ['@oksbi', '@okhdfcbank', '@okicici', '@okaxis'],
  },
  {
    displayName: '',
    imageUrl: imagePaytm,
    value: 'paytm',
    psp: ['@ptyes', '@ptaxis', '@pthdfc', '@ptsbi', '@paytm'],
  },
  {
    displayName: 'Other UPI Apps',
    value: 'others',
  },
];

// Map required to change the verification text based on the app selected.
export const UPIOptionToVerificationTextMap = {
  tez: 'Google Pay', // TODO: Change tez to gpay as and when backend does so
  paytm: 'Paytm',
  phonepe: 'PhonePe',
  bhim: 'BHIM',
  others: 'your UPI app',
};

export const UPIIntentList = [
  {
    displayName: '',
    imageUrl: imageGPay,
    value: 'tez',
    appName: 'Google Pay',
    postfix: '&/#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;end',
    androidPrefix: 'intent://',
    iosPrefix: 'gpay://upi/',
  },
  {
    displayName: '',
    imageUrl: imagePhonepe,
    value: 'phonepe',
    appName: 'PhonePe',
    postfix: '&/#Intent;scheme=upi;package=com.phonepe.app;end',
    androidPrefix: 'intent://',
    iosPrefix: 'ppe://',
  },
  {
    displayName: '',
    imageUrl: imagePaytm,
    value: 'paytm',
    appName: 'Paytm',
    postfix: '&/#Intent;scheme=upi;package=net.one97.paytm;end',
    androidPrefix: 'intent://',
    iosPrefix: 'paytmmp://upi/',
  },
  {
    displayName: '',
    imageUrl: imageBhim,
    appName: 'BHIM',
    value: 'bhim',
    postfix: '&/#Intent;scheme=upi;package=in.org.npci.upiapp;end',
    androidPrefix: 'intent://',
    iosPrefix: 'bhim://',
  },
  {
    displayName: 'Others',
    appName: 'your UPI',
    value: 'others',
    postfix: '',
    androidPrefix: 'upi://',
    iosPrefix: 'upi://',
  },
];

export const getUpiOptionFromPsp = (psp) => {
  switch (psp) {
    case '@okhdfcbank':
    case '@okicici':
    case '@okaxis':
    case '@oksbi': {
      return 'tez';
    }
    case '@upi': {
      return 'bhim';
    }
    case '@ybl': {
      return 'phonepe';
    }
    case '@paytm': {
      return 'paytm';
    }
    default: {
      return '';
    }
  }
};

export const getUpiDisplayNameFromPsp = (psp) => {
  switch (psp) {
    case '@okhdfcbank':
    case '@okicici':
    case '@okaxis':
    case '@oksbi': {
      return 'Google Pay';
    }
    case '@upi': {
      return 'BHIM';
    }
    case '@ybl': {
      return 'PhonePe';
    }
    case '@paytm': {
      return 'Paytm';
    }
    default: {
      return '';
    }
  }
};

export const UPIAutosuggestionList = [
  {
    id: 'ybl',
    imgSrc: imagePhonepe,
    altText: 'PhonePe',
    name: 'phonepe',
    appName: 'phonepe',
  },
  {
    id: 'okhdfcbank',
    imgSrc: imageGPay,
    altText: 'Google pay',
    name: 'tez',
    appName: 'google pay',
  },
  {
    id: 'okaxis',
    imgSrc: imageGPay,
    altText: 'Google pay',
    name: 'tez',
    appName: 'google pay',
  },
  {
    id: 'oksbi',
    imgSrc: imageGPay,
    altText: 'Google pay',
    name: 'tez',
    appName: 'google pay',
  },
  {
    id: 'okicici',
    imgSrc: imageGPay,
    altText: 'Google pay',
    name: 'tez',
    appName: 'google pay',
  },
  {
    id: 'upi',
    imgSrc: imageBhim,
    altText: 'BHIM',
    name: 'bhim',
    appName: 'bhim',
    imageClassNames: 'image-bhim',
  },
  {
    id: 'apl',
    imgSrc: imageAmazon,
    altText: 'Amazon Pay',
    name: 'Amazon Pay',
    appName: 'Amazon Pay',
    imageClassNames: 'image-amazon-airtel',
  },
  {
    id: 'axisbank',
    imgSrc: imageUPI,
    altText: 'BHIM Axis Pay',
    name: 'BHIM Axis Pay',
    appName: 'Axis Pay',
    imageClassNames: 'image-upi',
  },
  {
    id: 'barodampay',
    imgSrc: imageUPI,
    altText: 'BHIM Baroda Pay',
    name: 'BHIM Baroda Pay',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'citi',
    imgSrc: imageCiti,
    altText: 'Citi',
    name: 'Citi',
    appName: 'Citi',
    classNameTop: 'margin-top-text',
    imageClassNames: 'margin-bottom-image padding-top-image-citi',
  },
  {
    id: 'obc',
    imgSrc: imageUPI,
    altText: 'BHIM Oriental Pay',
    name: 'BHIM Oriental Pay',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'rbl',
    imgSrc: imageUPI,
    altText: 'BHIM RBL Pay',
    name: 'BHIM RBL Pay',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'allbank',
    imgSrc: imageUPI,
    altText: 'BHIM ALLBANK UPI',
    name: 'BHIM ALLBANK UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'indus',
    imgSrc: imageUPI,
    altText: 'BHIM IndusPay',
    name: 'BHIM IndusPay',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'federal',
    imgSrc: imageUPI,
    altText: 'BHIM Lotza UPI',
    name: 'BHIM Lotza UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'sbi',
    imgSrc: imageUPI,
    altText: 'BHIM SBIPay',
    name: 'BHIM SBIPay',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'yesbank',
    imgSrc: imageUPI,
    altText: 'BHIM YES PAY',
    name: 'BHIM YES PAY',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'hsbc',
    imgSrc: imageHSBC,
    altText: 'HSBC Simply Pay',
    name: 'HSBC Simply Pay',
    appName: 'HSBC Simply Pay',
    imageClassNames: 'image-hsbc',
  },
  {
    id: 'icici',
    imgSrc: imageIcici,
    altText: 'iMobile by ICICI Bank',
    name: 'iMobile by ICICI Bank',
    appName: 'iMobile by ICICI Bank',
    imageClassNames: 'image-icici',
  },
  {
    id: 'kotak',
    imgSrc: imageKotak,
    altText: 'Kotak Mobile Banking App',
    name: 'Kotak Mobile Banking App',
    appName: 'Kotak Mobile Banking App',
    imageClassNames: 'image-kotak',
  },
  {
    id: 'axisb',
    imgSrc: imageUPI,
    altText: 'CRED',
    name: 'CRED',
    appName: 'CRED',
    imageClassNames: 'image-upi',
  },
  {
    id: 'unionbankofindia',
    imgSrc: imageUPI,
    altText: 'BHIM Union Bank UPI App',
    name: 'BHIM Union Bank UPI App',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'unionbank',
    imgSrc: imageUPI,
    altText: 'BHIM Union Bank UPI App',
    name: 'BHIM Union Bank UPI App',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'uboi',
    imgSrc: imageUPI,
    altText: 'BHIM Union Bank UPI App',
    name: 'BHIM Union Bank UPI App',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'boi',
    imgSrc: imageUPI,
    altText: 'BHIM BOI UPI',
    name: 'BHIM BOI UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'mahb',
    imgSrc: imageUPI,
    altText: 'BHIM Maha UPI',
    name: 'BHIM Maha UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'cnrb',
    imgSrc: imageUPI,
    altText: 'BHIM Canara – eMPower',
    name: 'BHIM Canara – eMPower',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'centralbank',
    imgSrc: imageUPI,
    altText: 'BHIM Cent UPI',
    name: 'BHIM Cent UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'cbin',
    imgSrc: imageUPI,
    altText: 'BHIM Cent UPI',
    name: 'BHIM Cent UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'cboi',
    imgSrc: imageUPI,
    altText: 'BHIM Cent UPI',
    name: 'BHIM Cent UPI',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'pnb',
    imgSrc: imageUPI,
    altText: 'BHIM PNB',
    name: 'BHIM PNB',
    appName: '',
    imageClassNames: 'image-upi',
  },
  {
    id: 'airtel',
    imgSrc: imageAirtel,
    altText: 'Airtel',
    name: 'Airtel',
    appName: 'Airtel',
    imageClassNames: 'image-amazon-airtel',
  },
  {
    id: 'ptyes',
    imgSrc: imagePaytm,
    altText: 'Paytm',
    name: 'paytm',
    appName: 'paytm',
    imageClassNames: 'image-paytm',
  },
  {
    id: 'ptaxis',
    imgSrc: imagePaytm,
    altText: 'Paytm',
    name: 'paytm',
    appName: 'paytm',
    imageClassNames: 'image-paytm',
  },
  {
    id: 'pthdfc',
    imgSrc: imagePaytm,
    altText: 'Paytm',
    name: 'paytm',
    appName: 'paytm',
    imageClassNames: 'image-paytm',
  },
  {
    id: 'ptsbi',
    imgSrc: imagePaytm,
    altText: 'Paytm',
    name: 'paytm',
    appName: 'paytm',
    imageClassNames: 'image-paytm',
  },
  {
    id: 'paytm',
    imgSrc: imagePaytm,
    altText: 'Paytm',
    name: 'paytm',
    appName: 'paytm',
    imageClassNames: 'image-paytm',
  },
];
